html, body {
  height: 100%;
  min-height: 100%;
  position: relative;
}

body {
  overflow-x: hidden;
}

html.noscroll {
  overflow: hidden;
}
html.noscroll body {
  overflow: hidden;
}

#root {
  min-height: 100%;
  height: 100%;
  position: relative;
}
